import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  HashRouter
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import jwtDecode from "jwt-decode";
import config from "./config";
import { setAuthToken, cacheStore, isSuperadmin } from "./utils/helpers";
import { NotificationContainer } from "react-notifications";
import { isNil } from "lodash";
import "./sass/main.scss";
import "react-notifications/lib/notifications.css";

// Global Components
import AdminRoute from "./components/common/AdminRoute";
import LoadingBar from "react-redux-loading-bar";
import {
  logoutUser,
  setCurrentUser,
  clearCurrentProfile,
  fetchSession
} from "./actions/auth";

// Route Page
import Login from "./components/auth/Login";
import Verify from "./components/auth/Verify"
import Dashboard from "./components/dashboard";
import EditProfile from "./components/users/EditProfile";
import ChangePassword from "./components/users/ChangePassword";
import Blacklist from "./components/dashboard/BlacklistTable"

// Check for token
if (cacheStore().getItem(config.tokenKey)) {
  // Set auth token header auth
  setAuthToken(cacheStore().getItem(config.tokenKey));
  // Decode token and get user info and exp
  const payload = jwtDecode(cacheStore().getItem(config.tokenKey));
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(payload));
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <HashRouter basename="/app">
          <React.Fragment>
            <div className="body">
              <NotificationContainer />
              <LoadingBar scope="sectionBar" />
              <Route exact path="/" component={Login} />
              <Route path="/verify" component={Verify} />

              <Switch>
                <AdminRoute
                  name="Dashboard"
                  path="/dashboard"
                  component={Dashboard}
                />
              </Switch>

              <Switch>
                <AdminRoute
                  backto="/account"
                  name="Edit My Profile"
                  exact
                  path="/account/edit"
                  component={EditProfile}
                />
                <AdminRoute
                  backto="/dashboard"
                  name="Change Password"
                  exact
                  path="/account/password"
                  component={ChangePassword}
                />
                <AdminRoute
                  backto="/dashboard"
                  name="Phone No Blacklisted"
                  exact
                  path="/blacklisted"
                  component={Blacklist}
                />
              </Switch>
            </div>
          </React.Fragment>
        </HashRouter>
      </Provider>
    );
  }
}

export default App;
