import React, { Component, useCallback, useState } from "react"
import { MdComment, MdExpandLess, MdExpandMore } from "react-icons/md"
import classnames from "classnames"
import CryptoJS from "crypto-js"
import {
    createNotification,
    currency
} from "../../utils/helpers";
import { isEmpty, has, isNil, find } from "lodash";
import { FaTrash, FaForward, FaPaperPlane } from "react-icons/fa"
import Table from "./Table"
import MiniPreloader from "../ui/MiniPreloader"

export default function BlacklistPhoneTable({ 
    field,
    list, 
    count, 
    args, 
    page, 
    pages, 
    onPageNext, 
    onPageNumber, 
    onPagePrev,
    onRemoveBlacklist
}) {
    let title = ''
    if( field == 'email') {
        title = 'Email Address'
    } else if( field == 'phone_no' ) {
        title = 'Phone Number'
    }
    const pageSize = 30
    const columns = React.useMemo(
        () => [
            {
                Header: 'Info',
                columns: [
                    {
                        Header: title,
                        id:'identity',
                        accessor: (d) => {
                            return d[field]
                        },
                    },

                    {
                        Header: 'Added Date',
                        id:'created_date',
                        accessor: (d) => {
                            return d.created_date.short
                        },
                    },

                    {
                        Header: 'Action',
                        id:"action",
                        Cell: (row)=> (
                            <>
                                <div>
                                    

                                    <span 
                                        className="btn btn-outline-danger btn-sm"
                                        style={{cursor:'pointer'}}
                                        onClick={()=>onRemoveBlacklist(row.row.original.id)}>
                                            {'Revoke'}
                                    </span> 
                                </div>
                            </>
                        )
                    },
                ],
            }
        ],
        []
    )

    const data = React.useMemo(() => list, list)
    const countPages = Array.from(Array(pages).keys())

    return (
        <>
            <table className="table table-striped table-bordered">
                <Table className="-striped -bordered" columns={columns} data={data} />
            </table>
            {count > args.limit && <div className="Page navigation align-center mt-2">
                <ul className="pagination">
                    <li style={{marginRight:10}}>
                        <span style={{ lineHeight:2 }}>Page: {page} of {pages} </span>
                    </li>
                    <li className={classnames('page-item', {
                        'disabled':args.offset === 0
                    })}>
                        <a className="page-link" onClick={onPagePrev} href="#" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>

                    {pages > 1 && countPages.map((i)=>(
                        <li className={classnames('page-item', {
                            'active':page === parseInt(i+1)
                        })}>
                            <a className="page-link" onClick={(e)=> {
                                e.preventDefault(); 
                                onPageNumber(parseInt(i+1))
                            }} href="#" aria-label="Next">
                                <span aria-hidden="true">{parseInt(i+1)}</span>
                            </a>
                        </li>
                    ))}

                    <li className={classnames('page-item', {
                        'disabled': (args.limit + args.offset) > count
                    })}>
                        <a className="page-link" onClick={onPageNext} href="#" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                </ul>
            </div>}
        </>
    )
}
