import React, { Component, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
    fetchApplications,
    putNote,
    trashApp,
    putWorkStatus,
    sendFollowupEmail,
    addBlacklistIdentity
} from "../../actions/app";
import {
    createNotification,
    pageTitle,
    isStaff,
    currency,
    monthData
} from "../../utils/helpers";
import CryptoJS from "crypto-js"
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { isEmpty, has, isNil, merge, omit } from "lodash";
import classnames from "classnames"
import StaffTable from "../tables/StaffTable"
import AdminTable  from "../tables/AdminTable"
import Styled, { ThemeConsumer } from "styled-components"
import { MdComment, MdExpandLess, MdExpandMore, Md } from "react-icons/md"
import Preloader from "../ui/Preloader"

const MySwal = withReactContent(Swal);

const Styles = Styled.div`
    table {
        th
    }
`

class AppTable extends Component {
    constructor(props) {
        super(props);
        const curDate =  new Date()
        const mo = curDate.getMonth() + 1
        const yr = curDate.getFullYear()
        this.curMo = mo < 10 ? '0' + mo : mo
        this.state = {
            error: null,
            filters: [],
            args: {
                offset:0,
                limit:30,
                yr:yr,
                mo:this.curMo
            },
            loading: false,
            loadingNote:false,
            sending:false,
            note: '',
            page: 1,
            pages: 1,
            tab:'active'
        };
    }

    componentDidMount() {
        if (this.props.user) {
            if (isStaff(this.props.user)) {
                this.state.args.status = 'pending'
                this.forceUpdate()
                this.onFetchData('loading')
            } else {
                this.onFetchData('loading')
            }
        }
    }

    onFetchData(loader='loading') {
        this.setState({[loader]: true })
        this.props.fetchApplications(this.state.args).then(res => {
            const count = res.count
            const pages = Math.ceil(count / this.state.args.limit)
            this.setState({ [loader]: false, pages:pages })
        }).catch(err => {
            createNotification("error", "Failed retrieve data for this user");
        })
    }

    onSubmitNote(id, note, e) {
        this.props.putNote({
            id: id,
            action: "putNote",
            note: note
        }).then(res => {
            this.setState({ loadingNote: true })
            this.props.fetchApplications(this.state.args).then(res => {
                this.setState({ loadingNote: false })
            }).catch(err => {
                createNotification("error", "Failed retrieve data for this user");
            })
        })
    }

    onDelete(id) {
        MySwal.fire({
            title: "Are you sure want to permanent delete this application?",
            showCancelButton: true
          }).then(prompt => {
            if (has(prompt, 'value')) {
              MySwal.fire({
                title: <p>Loading...</p>,
                onOpen: () => {
                  MySwal.showLoading();
                  this.props.trashApp(id, {}).then(res => {
                    MySwal.fire({
                        type: "success",
                        title: "Great!",
                        text: "Application has been approved",
                        timer: 2000
                    });
                    this.onFetchData('loading')
                  }).catch(err => {
                    MySwal.fire({
                      type: "error",
                      title: "Error Exception!",
                      text: has(err, "data")
                        ? err.data.messages
                        : "Something went wrong"
                    });
                  })
                }
              });
            }
          });
    }

    async onSetBlacklistPhone(id) {

        /* inputOptions can be an object or Promise */
        const inputOptions = new Promise((resolve) => {
            setTimeout(() => {
            resolve({
                'phone_no': 'Phone Number',
                'email': 'Email',
            })
            }, 1000)
        })
        
        const {value:label} = Swal.fire({
            title: 'Please select one to blacklist',
            input: 'radio',
            inputOptions: inputOptions,
            inputValidator: (value) => {
                if (!value) {
                    return 'You need to choose phone number or email!'
                }
            }
        }).then(obj=>{
            if (obj.value) {
                MySwal.showLoading();
                this.props.addBlacklistIdentity(id, {type:obj.value}).then(res => {
                    let field
                    if( obj.value == 'email') {
                        field = 'Email'
                    } else if( obj.value == 'phone_no') {
                        field = 'Phono No'
                    }
                    MySwal.fire({
                        type: "success",
                        title: "Great!",
                        text: `${field} is successfully blacklisted`,
                        timer: 2000
                    });
                    this.onFetchData('loading')
                    }).catch(err => {
                        const resp = err.response
                        console.log(resp)
                        MySwal.fire({
                        type: "error",
                        title: "Error Exception!",
                        text: has(resp, "data.messages")
                            ? resp.data.messages
                            : "Something went wrong"
                        });
                    })
            }
        })

        console.log(label)

     
        
        /*MySwal.fire({
            title: "Are you sure want to blacklist this phone?",
            showCancelButton: true
          }).then(prompt => {
            if (has(prompt, 'value')) {
              MySwal.fire({
                title: <p>Loading...</p>,
                onOpen: () => {
                  MySwal.showLoading();
                  

                }
              });
            }
          });*/
    }

    onPageNext(e, number=null) {
        e.preventDefault()
        const { list } = this.props.apps
        let pageIndex
        if( number ) {
            pageIndex = number
        } else {
            pageIndex = this.state.page + 1
        }
        
        const offset = ( pageIndex - 1 ) * this.state.args.limit
        const args = {
            offset:offset,
            limit:this.state.args.limit
        }

        if( offset < list.count) {
            this.state.args.offset = offset
            this.state.page = pageIndex
            this.forceUpdate()
            this.onFetchData('loading')
        }
        
    }

    onPageNumber(number) {
        const { list } = this.props.apps
        const pageIndex = number
        const offset = ( pageIndex - 1 ) * this.state.args.limit
        const args = {
            offset:offset,
            limit:this.state.args.limit
        }

        if( offset < list.count) {
            this.state.args.offset = offset
            this.state.page = pageIndex
            this.forceUpdate()
            this.onFetchData('loading')
        }
        
    }

    onPagePrev(e) {
        e.preventDefault()
        const { list } = this.props.apps
        if( this.state.page == 1 ) 
            return

        const pageIndex = this.state.page - 1;
        const offset = ( pageIndex - 1 ) * this.state.args.limit
        const args = {
            offset:offset,
            limit:this.state.args.limit
        }

        if( offset < list.count) {
            this.state.args.offset = offset
            this.state.page = pageIndex
            this.forceUpdate()
            this.onFetchData('loading')
        }
        
    }

    onSetWorkStatus(id, status) {
        let title
        if( status == 'done') {
            title = 'Are you sure want to set complete this application?'
        } else if ( status == 'waiting') {
            title = 'Are you sure want to set undone this application?'
        }

        /*MySwal.fire({
            title: title,
            showCancelButton: true
          }).then(prompt => {
            if (has(prompt, 'value')) {
              MySwal.fire({
                title: <p>Loading...</p>,
                onOpen: () => {
                  MySwal.showLoading();

                  this.props.putWorkStatus({
                        id: id,
                        action: "putWorkStatus",
                        status: status
                    }).then(res => {
                    MySwal.fire({
                        type: "success",
                        title: "Great!",
                        text: "Status is updated",
                        timer: 2000
                    });
                    this.onFetchData('loading')
                  }).catch(err => {
                    MySwal.fire({
                      type: "error",
                      title: "Warning!",
                      text: has(err, "data")
                        ? err.data.messages
                        : "Something went wrong"
                    });
                  })

                }
              });
            }
          });*/
        this.props.putWorkStatus({
            id: id,
            action: "putWorkStatus",
            status: status
        }).then(res => {
            MySwal.fire({
                type: "success",
                title: "Great!",
                text: "Status is updated",
                timer: 2000
            });
            this.onFetchData('loading')
        }).catch(err => {
            MySwal.fire({
            type: "error",
            title: "Warning!",
            text: has(err, "data")
                ? err.data.messages
                : "Something went wrong"
            });
        })
    }

    setNote(e) {
        this.setState({ note: e.target.value })
    }

    showActiveList(e) {
        e.preventDefault()
        this.setState({tab:"active"}, () => {
            this.state.args.offset = 0
            this.state.args.work_status = 'waiting'
            this.state.page = 1
            this.forceUpdate()
            this.onFetchData('loading')
        })
        
    }

    showDoneList(e) {
        e.preventDefault()
        this.setState({tab:"done"}, () => {
            this.state.args.offset = 0
            this.state.args.work_status = 'done'
            this.state.page = 1
            this.forceUpdate()
            this.onFetchData('loading')
        })
    }

    onShowPage(e) {
        this.props.history.push(`/blacklisted?state=${e.target.value}`)
    }

    onRefresh() {
        this.state.args.offset = 0
        this.state.page = 1
        this.forceUpdate()
        this.onFetchData('loading')
    }

    onSendEmail(id, email) {
        this.setState({
            sending:id
        })

        MySwal.fire({
            title: <p>Sending to {email} </p>,
            onOpen: () => {
                MySwal.showLoading();
                this.props.sendFollowupEmail(id)
                    .then(res=>{
                        MySwal.fire({
                            type: "success",
                            title: "Great!",
                            text: "Email is sent",
                        });
                    })
                    .catch(err=>{
                        MySwal.fire({
                            type: "error",
                            title: "Warning!",
                            text: has(err, "data")
                                ? err.data.messages
                                : "Something went wrong"
                        });
                    
                    })
                    .finally(()=>{
                        this.props.fetchApplications(this.state.args)
                        this.setState({
                            sending:false
                        })
                    })
            }
          });

       
    }

    render() {
        const { list } = this.props.apps;

        if( isEmpty(list) ) {
            return  null
        }
       
        return (
            <>
                <div className="row mb-3 justify-content-between mt-4">
                    <div className="col-4">
                    Total {list.count} record(s) <br /> <span style={{ lineHeight:2 }}> {list.count > 0 && <span>Page: {this.state.page} of {this.state.pages} </span>}</span>                        
                    </div>
                    {isStaff(this.props.user) && <div className="col-4">

                        <ul className="nav nav-pills">
                            <li style={{lineHeight:2.4}}>Filter by </li>
                            <li className="nav-item">
                                <a className={classnames('nav-link', {
                                    'active':this.state.tab === 'active'
                                })} href="#" onClick={this.showActiveList.bind(this)}>Active</a>
                            </li>
                            <li className="nav-item">
                                <a className={classnames('nav-link', {
                                    'active':this.state.tab === 'done'
                                })} href="#" onClick={this.showDoneList.bind(this)}>Done</a>
                            </li>
                        </ul>
                    </div>}
                    <div className={classnames("text-right", {
                        'col-4':isStaff(this.props.user),
                        'col-5':!isStaff(this.props.user)
                    })}>
                        <button className="btn btn-outline-info col-4" style={{marginTop:0}} onClick={this.onRefresh.bind(this)}>Refresh</button>
                        <select name="page" id="" onChange={this.onShowPage.bind(this)} className="form-control ml-3 col-7" style={{display:"inline-block"}}>
                            <option value="">--Choose one--</option>
                            <option value="email" >Blacklist Email</option>
                            <option value="phone_no">Blacklist Phone Number</option>
                        </select>
                    </div>
                </div>

                <div className="row mb-3 justify-content-between mt-3" style={{fontSize:13}}>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-group">
                                        <label htmlFor="" className="font-weight-bold">Filter by : </label>
                                    </div>
                                <form className="form-inline">
                                  
                                    <div className="form-group mx-sm-2">
                                        <input type="email" onChange={(e)=>{
                                            this.state.args.email = e.target.value
                                            this.forceUpdate()
                                            this.onFetchData()
                                        }} className="form-control" id="email" placeholder="Enter email" />
                                    </div>

                                    <div className="form-group mx-sm-2">
                                        <input type="text" style={{width:150}} onChange={(e)=>{
                                            this.state.args.phone_no = e.target.value
                                            this.forceUpdate()
                                            this.onFetchData()
                                            
                                        }} className="form-control" id="phone_no" placeholder="Enter Phone No." />
                                    </div>

                                    {!isStaff(this.props.user) && <div className="form-group mx-sm-2">
                                        <label htmlFor="" className="mr-2">Status</label><br/>
                                        <select defaultValue={''} onChange={(e)=>{
                                            if( isEmpty(e.target.value) ) {
                                                let args = omit(this.state.args, ['status'])
                                                this.state.args = args
                                                this.forceUpdate()
                                            } else {
                                                this.state.args.status = e.target.value
                                                this.forceUpdate()
                                            }
                                           
                                            this.onFetchData()

                                        }} name="status" id="" className="form-control">
                                            <option value="">All</option>
                                            <option value="pending">Pending</option>
                                            <option value="approved">Accept</option>
                                            <option value="rejected">Rejected</option>
                                            <option value="followup">Follow-up</option>
                                        </select>
                                    </div>}

                                    <div className="form-group mx-sm-2">
                                        <label htmlFor="" className="mr-2">Month</label><br/>
                                        <select defaultValue={this.curMo} onChange={(e)=>{
                                            if( isEmpty(e.target.value) ) {
                                                let args = omit(this.state.args, ['mo'])
                                                this.state.args = args
                                            } else {
                                                this.state.args.mo = e.target.value
                                            }
                                            this.forceUpdate()
                                            this.onFetchData()

                                        }} name="mo" id="" className="form-control">
                                            <option value="">All</option>
                                            {monthData().map((mo,i)=> <option key={i} value={mo.key}>{mo.label}</option>)}
                                        </select>
                                    </div>

                                    <div className="form-group mx-sm-2">
                                        <label htmlFor="" className="mr-2">Year</label><br/>
                                        <select value={this.state.yr} onChange={(e)=>{
                                            this.state.args.yr = e.target.value
                                            this.forceUpdate()
                                            this.onFetchData()

                                        }} name="yr" id="" defaultValue="2020" className="form-control">
                                            <option value="2019">2019</option>
                                            <option value="2020">2020</option>
                                        </select>
                                    </div>
                                    
                                </form>
                            </div>
                        </div>                
                    </div> 
                </div>

                {this.state.loading && <Preloader />}
                {!this.state.loading && <div className="table-responsive">
                    {isStaff(this.props.user) ? <StaffTable 
                    user={this.props.user} 
                    list={list.data} 
                    loading={this.state.loadingNote} 
                    submitNote={this.onSubmitNote.bind(this)} 
                    count={list.count} 
                    args={this.state.args}
                    page={this.state.page}
                    pages={this.state.pages} 
                    tab={this.state.tab}
                    onPagePrev={this.onPagePrev.bind(this)} 
                    onPageNumber={this.onPageNumber.bind(this)} 
                    onPageNext={this.onPageNext.bind(this)}
                    onSetWorkStatus={this.onSetWorkStatus.bind(this)}
                    onSetBlacklistPhone={this.onSetBlacklistPhone.bind(this)}
        
                    /> : 
                    <AdminTable 
                    user={this.props.user} 
                    loading={this.state.loadingNote} 
                    submitNote={this.onSubmitNote.bind(this)} 
                    onDelete={this.onDelete.bind(this)} 
                    list={list.data} 
                    count={list.count} 
                    args={this.state.args}
                    page={this.state.page}
                    pages={this.state.pages}
                    onPagePrev={this.onPagePrev.bind(this)} 
                    onPageNumber={this.onPageNumber.bind(this)} 
                    onSendEmail={this.onSendEmail.bind(this)}
                    onPageNext={this.onPageNext.bind(this)} 
                    onSetBlacklistPhone={this.onSetBlacklistPhone.bind(this)}
                    sending={this.state.sending} /> }
                </div>}
            </>
        )
    }
}

AppTable.propTypes = {
    user: PropTypes.object.isRequired
};

const mapStatetoProps = state => ({
    apps: state.apps,
    user: state.auth.user.user,
});

export default connect(
    mapStatetoProps,
    { fetchApplications, putNote, trashApp, putWorkStatus, sendFollowupEmail, addBlacklistIdentity }
)(AppTable); 
