import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { logoutUser, clearCurrentProfile } from "../../actions/auth";
import { createNotification } from "../../utils/helpers";
import _ from "lodash";
import lang from "../../langs";
import {
  MdKeyboardBackspace,
  MdExitToApp,
  MdLock,
  MdArrowBack
} from "react-icons/md";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: ""
    };
  }

  onLogoutClick(e) {
    e.preventDefault();
    this.props.clearCurrentProfile();
    this.props.logoutUser();

    createNotification("info", lang("success.logout"));
  }

  render() {
    let backto = this.props.backto ? this.props.backto : "/dashboard";
    let isParent = this.props.backto ? false : true;
    if (this.props.location.pathname != "/dashboard") {
      if (this.props.location.hash) {
        backto = _.trimStart(this.props.location.hash, "#");
      }
    }

    return (
      <header className="mt-3 mb-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-8 col-offset-2">
              <div className="row ">
                <div className="col-10 col-md-10 justify-content-start">
                  {this.props.location.pathname === "/dashboard" && (
                    <Link to={"/account/password"} className="btn btn-info">
                      <MdLock size="1.5em" />
                    </Link>
                  )}

                  {this.props.location.pathname !== "/dashboard" && (
                    <Link
                      to={"/dashboard"}
                      className="btn btn-outline-secondary"
                    >
                      <MdArrowBack size="1.5em" />
                    </Link>
                  )}
                </div>
                <div class="col-2 col-md-2 justify-content-end">
                  <button
                    type="button"
                    onClick={this.onLogoutClick.bind(this)}
                    className="btn btn-outline-danger"
                  >
                    <MdExitToApp size="1.5em" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStatetoProps = state => ({
  auth: state.auth,
  merchants: state.merchants
});

export default connect(
  mapStatetoProps,
  { logoutUser, clearCurrentProfile }
)(Navbar);
