import React, { Component, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
    blacklist,
    removeBlacklistIdentity
} from "../../actions/app";
import {
    createNotification,
    pageTitle,
    isStaff,
    currency,
    monthData
} from "../../utils/helpers";
import { Link } from "react-router-dom"
import CryptoJS from "crypto-js"
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { isEmpty, has, isNil, merge, omit } from "lodash";
import classnames from "classnames"
import StaffTable from "../tables/StaffTable"
import AdminTable  from "../tables/AdminTable"
import Styled from "styled-components"
import { MdComment, MdExpandLess, MdExpandMore } from "react-icons/md"
import Preloader from "../ui/Preloader"
import BlacklistPhoneTable from "../tables/BlacklistPhoneTable";
import DocumentException from "../common/DocumentException";


const MySwal = withReactContent(Swal);

const Styles = Styled.div`
    table {
        th
    }
`

class BlacklistTable extends Component {
    constructor(props) {
        super(props);
        const curDate =  new Date()
        const mo = curDate.getMonth() + 1
        const yr = curDate.getFullYear()
        this.curMo = mo < 10 ? '0' + mo : mo
        this.state = {
            error: null,
            filters: [],
            key:'',
            args: {
                offset:0,
                limit:30,
                yr:yr,
                mo:this.curMo
            },
            loading: false,
            page: 1,
            pages: 1,
        };
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(this.props.location.search)
        const key = urlParams.get('state')
        this.onFetchData(key)
    }

    onFetchData(key='email') {
        this.setState({loading: true })
        this.props.blacklist({type:key}).then(res => {
            const count = res.data.count
            const pages = Math.ceil(count / this.state.args.limit)
            this.setState({loading: false, pages:pages, key:key})
        }).catch(err => {
            createNotification("error", "Failed retrieve data for this user");
        })
    }

    
    onShowPage(e) {
        this.setState({
            key:e.target.value
        })
        this.onFetchData(e.target.value)
    }
    
    onRemoveBlacklist(key, id) {
        let field
        if( key == 'phone_no' ) {
            field = 'Phone Number'
        } else if( key == 'email' ) {
            field = 'Email Address'
        }
        MySwal.fire({
            title: `Are you sure want to revoke this ${field}?`,
            showCancelButton: true
          }).then(prompt => {
            if (has(prompt, 'value')) {
              MySwal.fire({
                title: <p>Loading...</p>,
                onOpen: () => {
                  MySwal.showLoading();
                  this.props.removeBlacklistIdentity(id).then(res => {
                    MySwal.fire({
                        type: "success",
                        title: "Great!",
                        text: `${field} is successfully revoked`,
                        timer: 2000
                    });
                    this.onFetchData(key)
                  }).catch(err => {
                    const resp = err.response
                    console.log(resp)
                    MySwal.fire({
                      type: "error",
                      title: "Error Exception!",
                      text: has(resp, "data.messages")
                        ? resp.data.messages
                        : "Something went wrong"
                    });
                  })

                }
              });
            }
          });
    }

    onPageNext(e, number=null) {
        e.preventDefault()
        const { list } = this.props.apps
        let pageIndex
        if( number ) {
            pageIndex = number
        } else {
            pageIndex = this.state.page + 1
        }
        
        const offset = ( pageIndex - 1 ) * this.state.args.limit
        const args = {
            offset:offset,
            limit:this.state.args.limit
        }

        if( offset < list.count) {
            this.state.args.offset = offset
            this.state.page = pageIndex
            this.forceUpdate()
            this.onFetchData('loading')
        }
    }

    onPageNumber(number) {
        const { list } = this.props.apps
        const pageIndex = number
        const offset = ( pageIndex - 1 ) * this.state.args.limit
        const args = {
            offset:offset,
            limit:this.state.args.limit
        }

        if( offset < list.count) {
            this.state.args.offset = offset
            this.state.page = pageIndex
            this.forceUpdate()
            this.onFetchData('loading')
        }
        
    }

    onPagePrev(e) {
        e.preventDefault()
        const { list } = this.props.apps
        if( this.state.page == 1 ) 
            return

        const pageIndex = this.state.page - 1;
        const offset = ( pageIndex - 1 ) * this.state.args.limit
        const args = {
            offset:offset,
            limit:this.state.args.limit
        }

        if( offset < list.count) {
            this.state.args.offset = offset
            this.state.page = pageIndex
            this.forceUpdate()
            this.onFetchData('loading')
        }        
    }

    onRefresh() {
        this.state.args.offset = 0
        this.state.page = 1
        this.forceUpdate()
        this.onFetchData('loading')
    }

    render() {
        const { blacklist } = this.props.apps;

        if( isEmpty(blacklist) ) {
            return  null
        }
       
        return (
            <>
                <DocumentException
                    title={pageTitle("Blacklist")}
                    className="blacklistedPage"
                    history={this.props.history}
                    url={this.props.location.pathname}
                >
                    <div className="container">
                    <div className="row justify-content-md-center mt-3">
                        <div className="col-sm-12 col-lg-12 mb-3">
                        <div className="card mb-3">
                            <div className="card-header text-center">
                                <h5>Blacklisted {this.state.key == 'phone_no' ? 'Phone Number' : 'Email'}</h5>
                            </div>
                            <div className="card-body">
                                <React.Fragment>
                                    <div className="row m-2">
                                        <div className="col-12" style={{ minHeight: 200 }}>
                                            <div className="row mb-3 justify-content-between mt-4">
                                                <div className="col-7">
                                                    Total {blacklist.data.count} record(s) <br /> <span style={{ lineHeight:2 }}> {blacklist.data.count > 0 && <span>Page: {this.state.page} of {this.state.pages} </span>}</span>                        
                                                </div>
                                                
                                                <div className="col-5 text-right">
                                                    <Link className="btn col-4 btn-outline-info" style={{marginTop:0}} to={'/dashboard'}>Back to List</Link>
                                                    <select name="page" id="" onChange={this.onShowPage.bind(this)} style={{display:"inline-block",marginTop:0}} className="col-7 ml-2 form-control" value={this.state.key}>
                                                        <option value="email">Blacklist Email</option>
                                                        <option value="phone_no">Blacklist Phone Number</option>
                                                    </select>
                                                </div>
                                            </div>

                                            {this.state.loading && <Preloader />}
                                            {!this.state.loading && ! isEmpty(this.state.key) && ! isEmpty(blacklist.data) && <div className="table-responsive">
                                                <BlacklistPhoneTable 
                                                    field={this.state.key}
                                                    list={blacklist.data.list} 
                                                    loading={this.state.loading} 
                                                    count={blacklist.data.count} 
                                                    args={this.state.args}
                                                    page={this.state.page}
                                                    pages={this.state.pages} 
                                                    onPagePrev={this.onPagePrev.bind(this)} 
                                                    onPageNumber={this.onPageNumber.bind(this)} 
                                                    onPageNext={this.onPageNext.bind(this)}
                                                    onRemoveBlacklist={this.onRemoveBlacklist.bind(this, this.state.key)}
                                                />
                                            </div>}
                                        </div>
                                    </div>
                                </React.Fragment>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </DocumentException>
            </>
        )
    }
}

BlacklistTable.propTypes = {
    user: PropTypes.object.isRequired
};

const mapStatetoProps = state => ({
    apps: state.apps,
    user: state.auth.user.user,
});

export default connect(
    mapStatetoProps,
    { blacklist, removeBlacklistIdentity }
)(BlacklistTable); 
