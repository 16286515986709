import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  createNotification,
  pageTitle,
  isStaff,
  currency
} from "../../utils/helpers";
import DocumentException from "../common/DocumentException";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { isEmpty, has } from "lodash";
import AppTable from "./AppTable"
import { throws } from "assert";

const MySwal = withReactContent(Swal);

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sn: "",
      loading: false,
      qrcodeShow: false,
      result: null,
      agree: false,
      confirmApprove: false
    };
  }

  onApprove(e) {
    if (this.state.agree === false) {
      MySwal.fire({
        type: "warning",
        title: "Please tick and read agreements"
      });
      return;
    }
    MySwal.fire({
      title: "Are you sure want to approve this application?",
      text: "This button will change status to approved",
      showCancelButton: true
    }).then(prompt => {
      if (has(prompt, 'value')) {
        MySwal.fire({
          title: <p>Loading...</p>,
          onOpen: () => {
            MySwal.showLoading();
            this.props
              .approveApp(this.state.sn)
              .then(res => {
                MySwal.fire({
                  type: "success",
                  title: "Great!",
                  text: "Application has been approved",
                  timer: 2000
                });
                this.makeDefaultState();
              })
              .catch(err => {

                MySwal.fire({
                  type: "error",
                  title: "Error Exception!",
                  text: has(err, "data")
                    ? err.data.messages
                    : "Something went wrong"
                });
                this.makeDefaultState();
              });
          }
        });
      }
    });
  }

  makeDefaultState() {
    this.setState({
      loading: false,
      result: false,
      qrcodeShow: false,
      agree: false,
      sn: "",
      confirmApprove: false
    });
  }

  findApplication() {
    this.setState({
      loading: true,
      result: false
    });
    this.props.findApp(this.state.sn).then(res => {
      this.setState({
        loading: false,
        result: true,
        qrcodeShow: false
      });
    });
  }

  render() {
    const { user } = this.props.auth.user;

    return (
      <DocumentException
        title={pageTitle("Dashboard")}
        className="dashboardPage"
        history={this.props.history}
        url={this.props.location.pathname}
      >
        <div className="container">
          <div className="row justify-content-md-center mt-3">
            <div className="col-sm-12 col-lg-12 mb-3">
              <div className="card mb-3">
                <div className="card-header text-center">
                  <h5>Hi, {user.name}</h5>
                </div>
                <div className="card-body">
                  <React.Fragment>
                    <div className="row m-2">
                      <div className="col-12" style={{ minHeight: 200 }}>
                        <AppTable history={this.props.history} user={user} />
                      </div>
                    </div>
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DocumentException>
    );
  }
}

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStatetoProps = state => ({
  auth: state.auth,
  user: state.auth.user.user,
});

export default connect(
  mapStatetoProps,
  {}
)(Dashboard);
